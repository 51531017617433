<template>
  <b-row class="mt-2">
    <b-col
      :cols="selectedPlayground.bookingType === 'multiple'? 12 : 6"
    >
      <div
        v-if="!isLoading"
        class="d-flex"
      >
        <select
          v-model="selectedDuration"
          :disabled="$store.getters['playgrounds/getSelectedBlockPrices'].length === 0"
          size="sm"
          class="form-control background-light-blue-inputs"
          @change="onDurationSelect"
        >
          <option
            disabled
            :value="null"
          >
            {{ $t('general.actions.choose') }}
          </option>
          <template
            v-if="serializedDurations"
            v-for="(duration, i) in serializedDurations"
          >
            <option
              v-if="duration.viewed"
              :value="duration.value"
              :key="'serialized-duration'+ i"
            >
              {{ selectedPlayground.bookingType === 'multiple' ? duration.item.name + ' - ' + duration.text : duration.text }}
            </option>
          </template>
        </select>
        <span
          v-if="$store.getters['playgrounds/getSelectedBlockPrices'].length === 0"
          :title="$t('views.bookings.index.new.error-field')"
          data-toggle="tooltip"
          class="pl-1 d-flex align-items-center"
        >
          <i class="text-danger btn-close-size fa fa-info-circle">
          </i>
        </span>
      </div>
      <b-spinner
        v-else
        variant="blue-light-spinner"
        type="grow"
      ></b-spinner>
    </b-col>
    <b-col
      v-if="selectedPlayground.bookingType !== 'multiple'"
      cols="6"
    >
      <div v-if="!isLoading" class="d-flex">
        <select
          v-model="selectedNbPlayers"
          :disabled="$store.getters['playgrounds/getSelectedBlockPrices'].length === 0"
          size="sm"
          class="form-control background-light-blue-inputs"
          @change="onNbPlayersSelect"
        >
          <option disabled :value="null">
            {{ $t('general.actions.choose') }}
          </option>
          <template
            v-if="selectedNbPlayers"
            v-for="nb of serializedNbPlayers"
          >
            <option
              v-if="nb.viewed"
              :value="nb.value"
            >
              {{ nb.text }}
            </option>
          </template>
        </select>
        <span
          v-if="$store.getters['playgrounds/getSelectedBlockPrices'].length === 0"
          class="pl-1 d-flex align-items-center"
          data-toggle="tooltip"
          :title="$t('views.bookings.index.new.error-field')"
        >
          <i class="text-danger btn-close-size fa fa-info-circle">
          </i>
        </span>
      </div>
      <b-spinner
        v-else
        variant="blue-light-spinner"
        type="grow"
      ></b-spinner>
    </b-col>
  </b-row>
</template>
<script>
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {getPricesPlayground} from "@api/doinsport/services/timetable/blocks/price/price.api";
import {DEFAULT_BOOKING_TYPE} from "@/classes/doinsport/BookingModel";
import {FORMULA_TYPE, LEISURE_TYPE, LESSON_TYPE, SPORT_TYPE} from "@/classes/doinsport/Price";
import {MULTIPLE_BOOKING_TYPE} from "@/classes/doinsport/Playground";

export default {
  props: {
    booking: {
      type: Object,
      default: this,
    },
    bookingType: {
      type: String,
      default: DEFAULT_BOOKING_TYPE,
    },
    slotData: {
      type: Object,
      default: null,
    },
    selectedPlayground: {
      type: Object,
      default: this,
    }
  },
  data: () => ({
    selectedActivity: null,
    isLoading: false,
    selectedNbPlayers: 'null',
    selectedDuration: 'null',
    serializedDurations: [],
    serializedNbPlayers: [],
    blockPrices: [],
  }),
  watch: {
    booking: {
      deep: true,
      handler() {
        if (this.booking.activity !== this.selectedActivity) {
          this.selectedActivity = this.booking.activity;
          this.loadTimetableBlockPrices();
        }
      }
    },
    selectedDuration: function () {
      if (this.selectedPlayground.bookingType === 'multiple') {
        this.$emit('on:timetable-block-price:changed');
      } else {
        if (this.blockPrices.length > 0) {
          const recurrence = [];
          const blockPriceFromDuration = this.blockPrices.find(el => el['@id'] === this.selectedDuration);
          const duration = blockPriceFromDuration.duration / 60 + ' ' + this.$t('minutes');
          let updated = false;

          for (const serializedNbPlayer of this.serializedNbPlayers) {
            const blockPriceFromNbPlayer = this.blockPrices.find(el => el['@id'] === serializedNbPlayer.value);
            const blockPriceFromNbPlayerDuration = blockPriceFromNbPlayer.duration / 60 + ' ' + this.$t('minutes');

            if (blockPriceFromNbPlayerDuration === duration) {
              const checkIfExist = recurrence.find(el => el === serializedNbPlayer.text);
              if ("undefined" === typeof checkIfExist) {
                recurrence.push(serializedNbPlayer.text);
                serializedNbPlayer.viewed = true;
                if ("undefined" === typeof this.$route.params.id && !updated) {
                  this.selectedNbPlayers = serializedNbPlayer.value;
                  this.booking.timetableBlockPrice = this.selectedNbPlayers;
                  updated = true;
                }
              }
            } else {
              serializedNbPlayer.viewed = false;
            }
          }
        }
      }
    },
  },
  methods: {
    formatDuration(duration) {
      return duration / 60 + ' ' + this.$t('minutes');
    },
    initSelectOptions() {
      if (this.slotData) {
        if (this.slotData.blockPrice === null) {
          this.selectedDuration = this.serializedDurations[0].item['@id'];
          let duration = this.serializedDurations[0].item.duration;
          this.selectedNbPlayers = this.serializedDurations[0].item['@id'];

          const endAt = this.booking.startAt.clone();
          const selectedDurationInMN = this.blockPrices.find(el => el['@id'] === this.selectedDuration);
          this.booking.endAt = endAt.add(selectedDurationInMN.duration / 60, 'minutes');

          for (const blockPrice of this.serializedDurations) {
            if (blockPrice.item.duration < duration) {
              duration = blockPrice.duration;
              this.selectedDuration = blockPrice.item['@id'];
              this.selectedNbPlayers = blockPrice.item['@id'];
              this.booking.timetableBlockPrice = blockPrice.item['@id'];
            }
          }
        } else {
          this.selectedNbPlayers = this.slotData.blockPrice;
          this.selectedDuration = this.slotData.blockPrice;
        }

        const selectedBlockPrice = this.blockPrices.find(el => el['@id'] === this.selectedDuration);

        if (isNotUndefinedAndNotNull(selectedBlockPrice)) {
          for (const duration of this.serializedDurations) {
            if (duration.value === this.selectedDuration) {
              const durationInMn = parseInt(duration.text.split(' ')[0]);
              const endAt = this.slotData.date.clone();
              if (parseInt(this.slotData.step) > durationInMn) {
                this.booking.endAt = endAt.add(durationInMn, 'minutes');
              }
            }
          }
        }
      } else {
        if (this.$route.params.id) {
          this.selectedDuration = this.booking.timetableBlockPrice;
          this.selectedNbPlayers = this.booking.timetableBlockPrice;
        } else {
          this.selectedDuration = this.serializedDurations[0].item['@id'];
          this.selectedNbPlayers = this.serializedDurations[0].item['@id'];
          this.booking.timetableBlockPrice = this.serializedDurations[0].item['@id'];
        }
      }
      this.booking.timetableBlockPrice = this.selectedNbPlayers;

      setTimeout(() => {
        this.$bus.$emit('on:refresh-participants');
      }, 300)
    },
    loadSerializedDurations() {
      const data = [];

      if (this.slotData) {
        const blockIri = '/clubs/playgrounds/timetables/blocks/' + this.slotData.blockId;
        this.blockPrices.forEach(item => {
          if (this.hasNoLessonType(item)) {
            const findBlockIri = item.blocks.find(el => el === blockIri);

            if (isNotUndefinedAndNotNull(findBlockIri)) {
              const findDurations = data.find(el => el.text === (item.duration / 60) + ' ' + this.$t('minutes'));

              data.push({
                text: item.duration / 60 + ' ' + this.$t('minutes'),
                value: item['@id'],
                viewed: "undefined" === typeof findDurations || this.selectedPlayground.bookingType === MULTIPLE_BOOKING_TYPE,
                item: item,
              });
            }
          }
        });
      } else {
        const selectedBookingBlockPrice = this.blockPrices.find(el => el ['@id'] === this.booking.timetableBlockPrice);
        if (isNotUndefinedAndNotNull(selectedBookingBlockPrice)) {
          data.push({
            text: selectedBookingBlockPrice.duration / 60 + ' ' + this.$t('minutes'),
            value: selectedBookingBlockPrice['@id'],
            viewed: true,
            item: selectedBookingBlockPrice,
          });
        }
        for (const blockPrice of this.blockPrices) {
          if (this.hasNoLessonType(blockPrice)) {
            const findDurations = data.find(el => el.text === (blockPrice.duration / 60) + ' ' + this.$t('minutes'));
            data.push({
              text: blockPrice.duration / 60 + ' ' + this.$t('minutes'),
              value: blockPrice['@id'],
              viewed: "undefined" === typeof findDurations || this.selectedPlayground.bookingType === MULTIPLE_BOOKING_TYPE,
              item: blockPrice,
            });
          }
        }
      }

      this.serializedDurations = data.sort((a, b) => a.item.duration - b.item.duration);
    },
    loadSerializedNbPlayers() {
      const data = [];
      if (this.slotData) {
        const blockIri = '/clubs/playgrounds/timetables/blocks/' + this.slotData.blockId;

        this.blockPrices.forEach(item => {
          if (this.hasNoLessonType(item)) {
            const findBlockIri = item.blocks.find(el => el === blockIri);
            if ("undefined" !== typeof findBlockIri) {
              const findParticipantsCountLimit = data.find(el => el.text === item.maxParticipantsCountLimit + ' ' + this.$t('players'));

              if (!isNotUndefinedAndNotNull(findParticipantsCountLimit)) {
                data.push({
                  text: item.maxParticipantsCountLimit + ' ' + this.$t('players'),
                  value: item['@id'],
                  viewed: true,
                  item: item,
                });
              } else {
                data.push({
                  text: item.maxParticipantsCountLimit + ' ' + this.$t('players'),
                  value: item['@id'],
                  viewed: false,
                  item: item,
                });
              }
            }
          }
        });
      } else {
        if (this.$route.params.id) {
          const currentBlockPrice = this.blockPrices.find(el => el['@id'] === this.booking.timetableBlockPrice);

          data.push({
            text: currentBlockPrice.maxParticipantsCountLimit + ' ' + this.$t('players'),
            value: currentBlockPrice['@id'],
            viewed: true,
            item: currentBlockPrice,
          });
        }
        for (const blockPrice of this.blockPrices) {
          if (this.hasNoLessonType(blockPrice)) {
            const findParticipantsCountLimit = data.find(el => el.text === blockPrice.maxParticipantsCountLimit + ' ' + this.$t('players'));

            if (!isNotUndefinedAndNotNull(findParticipantsCountLimit)) {
              data.push({
                text: blockPrice.maxParticipantsCountLimit + ' ' + this.$t('players'),
                value: blockPrice['@id'],
                viewed: true,
                item: blockPrice
              });
            } else {
              data.push({
                text: blockPrice.maxParticipantsCountLimit + ' ' + this.$t('players'),
                value: blockPrice['@id'],
                viewed: false,
                item: blockPrice
              });
            }
          }
        }
      }
      this.serializedNbPlayers = data.sort((a, b) => a.item.maxParticipantsCountLimit - b.item.maxParticipantsCountLimit);
    },
    hasNoLessonType(item) {
      return item.activityType !== LESSON_TYPE && item.activityType !== FORMULA_TYPE;
    },
    onDurationSelect() {
      if (isNotUndefinedAndNotNull(this.selectedDuration)) {
        this.booking.timetableBlockPrice = this.selectedDuration;
        this.selectedNbPlayers = this.selectedDuration;
        const maxDuration = Math.max(...this.serializedDurations.map(duration => (duration.item.duration) / 60), 0);

        for (const duration of this.serializedDurations) {
          if (duration.value === this.selectedDuration) {
            const durationInMn = parseInt(duration.text.split(' ')[0]);
            const diffTime = this.$moment(this.booking.endAt).diff(this.booking.startAt, 'minutes');

            if (this.slotData) {
              if (diffTime <= maxDuration) {
                const endAt = this.slotData.date.clone();
                this.booking.endAt = endAt.add(durationInMn, 'minutes');
              }
            } else {
              if (diffTime <= maxDuration) {
                const endAt = this.booking.startAt.clone();
                this.booking.endAt = endAt.add(durationInMn, 'minutes');
              }
            }
          }
        }
      }
    },
    onNbPlayersSelect() {
      this.booking.timetableBlockPrice = this.selectedNbPlayers;
    },
    loadTimetableBlockPrices() {
      this.isLoading = true;
      this.blockPrices = [];
      this.$store.dispatch('playgrounds/setLoader', true);

      if (this.booking.playgrounds.length > 0 && this.selectedActivity) {
        const filters = this.selectedPlayground.bookingType === 'multiple' ? [LESSON_TYPE, LEISURE_TYPE, FORMULA_TYPE] : [LESSON_TYPE, SPORT_TYPE, FORMULA_TYPE];

        getPricesPlayground(this.booking.playgrounds[0].replace('/clubs/playgrounds/', ''), this.booking.activity.replace('/activities/', ''), filters)
          .then(resp => {
            if (resp.data) {
              this.blockPrices = resp.data['hydra:member'];
              this.blockPrices.forEach(item => {
                if (this.slotData) {
                  if (this.slotData.blockPrice === null) {
                    this.booking.timetableBlockPrice = item['@id'];
                  } else if (this.slotData.blockPrice['@id'] === item['@id']) {
                    this.booking.timetableBlockPrice = item['@id'];
                  }
                }
              });
              if (this.slotData && isNotUndefinedAndNotNull(this.booking.timetableBlockPrice)) {
                const duration = this.booking.timetableBlockPrice.duration;
                this.booking.endAt = this.$moment(this.booking.endAt).add(duration, 'seconds');
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.$store.dispatch('playgrounds/saveBlockPricesSelection', this.blockPrices);
            this.$store.dispatch('playgrounds/setLoader', false);
            if (this.blockPrices.length > 0) {
              this.loadSerializedDurations();
              this.loadSerializedNbPlayers();
              if (this.serializedDurations.length > 0 && this.serializedNbPlayers.length > 0) {
                this.initSelectOptions();
                this.$bus.$emit('on:playground-block-prices:loaded');
              } else {
                this.$bus.$emit('update:switch');
              }
            } else {
              this.selectedDuration = null;
              this.selectedNbPlayers = null;
            }
          })
        ;
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch('playgrounds/saveBlockPricesSelection', []);
  },
  created() {
    this.selectedActivity = this.booking.activity;
    this.loadTimetableBlockPrices();
  },
}
</script>
